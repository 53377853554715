import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import '../../resources/main.scss';
import '../AddTranzaction/AddTranzaction.scss'
import Modal from 'react-modal';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import {contactService} from '../../services/ContactService';
import {loginService} from '../../services/LoginService';
import {errorService} from '../../services/ErrorService';


const customStyles = {
    overlay: {
      zIndex: 12,
      backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      height: '85%',
    }
  };

class AddContact extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
                contact: {
                    name:'',
                    phone:'',
                    email:''
              }
        };
        this.notificationSystem = React.createRef();
      }


      updateField = event => {
        const field = event.target.name;
        const contact = { ...this.state.contact };
        contact[field] = event.target.value;
        this.setState({
            contact
            });
      };
      handleSubmit=event=>{
        event.preventDefault();
        const notification = this.notificationSystem.current;
         contactService.addContact(this.state.contact).then(response=>{
             notification.addNotification({
                 message: `Contactul a fost adaugat cu succes.`,
                 level: 'success',
                 position: 'tc'
               });
         })
         .catch(error=>{
             if (error.response.status === 401) {
               errorService.handleUnauthorized();
               this.props.history.push("/login");

               return;
             }

             if (error.response.status === 422) {
               notification.addNotification({
                 message:
                   'Un contact cu numarul de telefon sau email-ul adaugat exista deja in baza de date, va rugam sa cautati in contactele existente.',
                 level: 'error',
                 position: 'tc'
               });

               return;
             }

               notification.addNotification({
                 message:
                   'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
                 level: 'error',
                 position: 'tc'
               });
         })
    }



    render(){
        return(
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="tranzactions Data"
                    ariaHideApp={false}
                >
            <div className='standard add-tranzaction'>
                <img src='/images/close.png' className='add-tranzaction__close-icon' alt='close' onClick={this.props.closeModal}/>
                <h3 className='add-tranzaction__title'>Adaugă contact</h3>
                <form className="add-tranzaction__form" onSubmit={this.handleSubmit}>
                <h3 className='add-tranzaction__label'>Adaugă nume:</h3>
                    <input

                        className="add-tranzaction__field"
                        type="text"
                        name="name"
                        onChange={this.updateField}
                        value={this.state.contact.name}
                      />
                 <h3 className='add-tranzaction__label'>Adaugă numar de telefon:</h3>
                    <input

                        className="add-tranzaction__field"
                        type="number"
                        name="phone"
                        onChange={this.updateField}
                        value={this.state.contact.phone}
                      />
                <h3 className='add-tranzaction__label'>Adaugă email:</h3>
                    <input

                        className="add-tranzaction__field"
                        type="email"
                        name="email"
                        onChange={this.updateField}
                        value={this.state.contact.email}
                      />


                      <div className='add-tranzaction__submit-buttons'>
                      <button
                        type="submit"
                        className="add-tranzaction__submit-button"
                      >
                          <h5>ADAUGĂ CONTACT</h5>
                      </button>
                      <button
                        className="add-tranzaction__cancel"
                        onClick={this.props.closeModal}
                      >
                          <h5>ANULEAZĂ</h5>
                      </button>
                      </div>
                    </form>
            </div>
            <NotificationSystem
                      ref={this.notificationSystem}
                      style={NotificationSystemStyle}
                    />
      </Modal>
            </div>
        )
    }
}

export default AddContact;
