import React from 'react';
import '../../resources/main.scss';
import {Link} from 'react-router-dom';
import MobileMenu from '../../components/MobileMenu/MobileMenu';
import Menu from '../../components/Menu/Menu.jsx';
import agentsJson from '../AllAgents/agents.json';
import AddContact from '../../components/AddContact/AddContact';
import {contactService} from '../../services/ContactService';
import {loginService} from '../../services/LoginService';

class Contacts extends React.Component{
    constructor(props){
        super(props);

        this.state={
          contacts:[],
          isContactModalOpen:false,
          showSearch: false,
          searchInfo: {
            searchName: '',
            phone: '',
            email: ''
          }
        }

    }

    componentDidMount(){
        contactService.getContacts().then(response=>{
            let contacts=this.state.contacts;
            contacts=response.data;
            this.setState({contacts})
        })
    }

    openContactModal=()=>{
        this.setState({isContactModalOpen:true})
    }

    closeModal = () => {
      this.setState({ isContactModalOpen: false });
    };

    toggleSearch=()=>{
      let showSearch=this.state.showSearch;
      showSearch=!showSearch;
      this.setState({showSearch})
    }

    updateField = event => {
        const field = event.target.name;
        const searchInfo = { ...this.state.searchInfo };
        searchInfo[field] = event.target.value;
        this.setState({
            searchInfo
            });
      };

    handleSubmit = event => {
      event.preventDefault();

      contactService.searchContacts(this.state.searchInfo)
        .then(response => {
          let contacts=this.state.contacts;
          contacts=response.data.contacts;
          this.setState({contacts})
        })
        .catch(error => {
          console.log(error);
        })
    }

    render(){
        return(
            <div className='standard agent agents'>
                <Menu/>
                <MobileMenu/>
                <div className='agent__page-content agents__page-content'>
                    <div className='agent__container'>
                    <button className='standard__only-desktop agents__add-button'onClick={()=>this.openContactModal()}><img src='/images/add-user.png' className='agents__add-icon'/>Adauga contact</button>
                        <img src='/images/logo.png' alt='logo' className='agent__logo'/>
                        <h2 className='agent__title'>Contacte</h2>
                      <button className='agents__add' onClick={()=>this.openContactModal()}><img src='/images/add-user.png' className='agents__add-icon'/>Adauga contact</button>
                      <div className='spendings__search-area'>
                        <form className='agent__form spendings__form' onSubmit={this.handleSubmit}>
                          <div className='spendings__search-title' onClick={()=>this.toggleSearch()}>
                              <div>
                              <h3>Căutare</h3>
                              <img src='/images/search-green.png' alt='search' className='spendings__top-search-icon'/>
                              </div>
                              <img src='/images/arrow-down.png' alt='arrow down' className='spendings__arrow-icon'/>
                          </div>
                          {this.state.showSearch ?
                            <div className='spendings__full-search cashings__full-search'>
                              <div className='spendings__search-box-full'>
                                <h4 className='agent__label'>Nume:</h4>
                                <input
                                  className='agent__input spendings__input'
                                  type="text"
                                  name="searchName"
                                  onChange={this.updateField}
                                  value={this.state.searchInfo.searchName}/>
                                </div>
                                <div className='spendings__search-box-full'>
                                  <h4 className='agent__label'>Telefon:</h4>
                                  <input
                                    className='agent__input spendings__input'
                                    type="text"
                                    name="phone"
                                    onChange={this.updateField}
                                    value={this.state.searchInfo.phone}/>
                                  </div>

                                <div className='spendings__search-box-full'>
                                  <h4 className='agent__label'>Email:</h4>
                                  <input
                                    className='agent__input spendings__input'
                                    type="text"
                                    name="email"
                                    onChange={this.updateField}
                                    value={this.state.searchInfo.email}/>
                                  </div>
                                  <button type='submit' className='agent__submit-button spendings__submit-button'>
                                    <img src='/images/search.png' alt='search'/><h5 className='agent__button-writing'>CAUTĂ</h5>
                                  </button>
                             </div>:''}
                            </form>
                      </div>
                       <div className='spendings__full-wrapper'>
                        <div className='spendings__wrapper'>
                            <div className='spendings__heading'>
                                <h4 className='spendings__head-title '>Nume</h4>
                                <h4 className='spendings__head-title'>Numar de telefon</h4>
                                <h4 className='spendings__head-title'>Email</h4>
                            </div>
                            {this.state.contacts.map(contact=>(
                                <div className='spendings__row agent__row' key={contact.id}>
                                    <div className='spendings__cell'>
                                        <h4>{contact.name}</h4>
                                    </div>
                                    <div className='spendings__cell'>
                                        <h4>{contact.phone}</h4>
                                    </div>

                                    <div className='spendings__cell'>
                                        <h4>{contact.email}</h4>
                                    </div>
                                </div>
                            ))}

                        </div>
                        </div>
                    </div>
                    </div>
                    { this.state.isContactModalOpen && <AddContact
                    modalIsOpen={this.state.isContactModalOpen}
                    closeModal={this.closeModal}
              /> }
            </div>
        )
    }
}

export default Contacts;
