import React from 'react';
import './AddContract.scss';
import '../../resources/main.scss';
import AttachementField from '../../components/AttachementField/AttachementField';
import {Link} from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import MobileMenu from '../../components/MobileMenu/MobileMenu';
import Menu from '../../components/Menu/Menu.jsx';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import AddTranzaction from '../../components/AddTranzaction/AddTranzaction';
import AddPartialSum from '../../components/AddPartialSum/AddPartialSum';
import { contractService } from '../../services/ContractService';
import {errorService} from '../../services/ErrorService';

class AddContract extends React.Component{
    constructor(props){
        super(props);

        this.state={
          contract:{
            name:'',
            quantity:'',
            tranzactionType:'',
            sum:'',
            contractDate:'',
            document:''
          },
          disabledDocument: false,
          isTranzactionModalOpen:false,
          isPartialSumModalOpen:false
        }
        this.notificationSystem = React.createRef();
    }

     updateField = event => {
        const field = event.target.name;
        const contract = { ...this.state.contract };
        contract[field] = event.target.value;
        this.setState({
            contract
            });
      };
      onDropDocument = documents => {
        console.log(documents)
        let contract = { ...this.state.contract };
        let reader = new FileReader();
        let self = this;

        reader.onload = function(upload) {
            contract.document = documents[0];

          self.setState({ contract });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };

      removeDocument = () => {
        let contract = { ...this.state.contract };
        contract.document = null;
        contract.document_filename = '';

        this.setState({ contract });
        this.setState({ disabledDocument: false });
      }

      openTranzactionModal=()=>{
        this.setState({isTranzactionModalOpen:true})
      }

      openPartialSum=()=>{
        this.setState({isPartialSumModalOpen:true})
      }

      closeModal = () => {
        this.setState({ isTranzactionModalOpen: false });
        this.setState({ isPartialSumModalOpen: false });
      };

      addContractFormSubmit = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;

        contractService.createOrUpdate(this.state.contract)
          .then(response => {
            notification.addNotification({
                message: `Contractul a fost adaugat cu succes.`,
                level: 'success',
                position: 'tc'
              });
          })
          .catch(error => {
            if (error.response.status === 401) {
                errorService.handleUnauthorized();
                this.props.history.push("/login");
              }

            notification.addNotification({
              message:
                'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
              level: 'error',
              position: 'tc'
            });
          })
      }
    render(){
        return(
            <div className='standard agent spending'>
                <Menu/>
                <MobileMenu/>
                <div className='agent__page-content'>
                    <div className='agent__container'>
                        <img src='/images/logo.png' alt='logo' className='agent__logo'/>
                        <h2 className='agent__title'>Formular Contract</h2>
                        <form className='agent__form' onSubmit={this.addContractFormSubmit}>
                        <div>
                            <h4 className='agent__label'>Denumire:</h4>
                              <input
                                className='agent__input'
                                type="text"
                                name="name"
                                onChange={this.updateField}
                                value={this.state.contract.name}/> </div>
                            <div className='cashing__double-unit'>
                                <div className='cashing__unit'>
                                <h4 className='agent__label'>Cantitate:</h4>
                                <input
                                    className='agent__input'
                                    type="text"
                                    name="quantity"
                                    onChange={this.updateField}
                                    value={this.state.contract.quantity}/>
                                </div>
                            <div className='cashing__unit'>
                                    <h4 className='agent__label'>Data adaugarii:</h4>
                                <input
                                    className='agent__input'
                                    type="date"
                                    name="contractDate"
                                    onChange={this.updateField}
                                    value={this.state.contract.contractDate}/> </div>
                            </div>
                                 <div className='cashing__double-unit'>
                                <div className='cashing__unit'>
                                    <h4 className='agent__label'>Tip tranzacție:</h4>
                                    <select
                                        className="spendings__select"
                                        title="type"
                                        value={this.state.contract.transactionType}
                                        name="transactionType"
                                        onChange={this.updateField}
                                        >
                                          <option value="">Tip</option>
                                          <option value="vanzare">Vanzare</option>
                                          <option value="inchiriere">Închiriere</option>
                                    </select>
                                    </div>
                                    <div className='cashing__unit'>
                                <h4 className='agent__label'>Valoare Tranzacție (RON)</h4>
                              <input
                                className='agent__input'
                                type="number"
                                name="sum"
                                onChange={this.updateField}
                                value={this.state.contract.sum}/>
                                </div>

                                </div>
                        <h4 className='agent__label'>Document Contract/AnteContract:</h4>
                         <AttachementField
                              onDrop={this.onDropDocument}
                              removeDocument={this.removeDocument}
                              disabledDocuments={this.state.disabledDocument}
                              document={this.state.contract.document}
                              documentFilename={
                                  this.state.contract.document_filename
                              }
                              type="application/pdf"
                            />
                            <div className='spending__account-box'>
                                <button type='submit' className='agent__submit-button'><h5 className='agent__button-writing'>ADAUGĂ CONTRACT</h5></button>
                            </div>
                        </form>
                        <div>
                          <h2 className='agent__title'>Agenti Participanti la Contract</h2>
                          <button type='button' className='agent__submit-button'><h5 className='agent__button-writing'>ADAUGĂ AGENT</h5></button>
                        </div>
                        <div>
                          <h2 className='agent__title'>Contacte din Contract</h2>
                          <button type='button' className='agent__submit-button'><h5 className='agent__button-writing'>ADAUGĂ CONTACT</h5></button>
                        </div>
                        <div>
                          <h2 className='agent__title'>Sume platite la contract</h2>
                          <button type='button' className='agent__submit-button'><h5 className='agent__button-writing'>ADAUGĂ SUMA</h5></button>
                        </div>
                    </div>
                    </div>
                    { this.state.isTranzactionModalOpen && <AddTranzaction
                    modalIsOpen={this.state.isTranzactionModalOpen}
                    closeModal={this.closeModal}
              /> }
              { this.state.isPartialSumModalOpen && <AddPartialSum
                    modalIsOpen={this.state.isPartialSumModalOpen}
                    closeModal={this.closeModal}
              /> }
                    <NotificationSystem
                      ref={this.notificationSystem}
                      style={NotificationSystemStyle}
                    />
            </div>
        )
    }
}

export default AddContract;
