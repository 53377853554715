import React from 'react';
import './AddSpending.scss';
import '../../resources/main.scss';
import AttachementField from '../../components/AttachementField/AttachementField';
import {Link} from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import MobileMenu from '../../components/MobileMenu/MobileMenu';
import Menu from '../../components/Menu/Menu.jsx';
import {spendingService} from '../../services/SpendingService';
import {loginService} from '../../services/LoginService';
import {errorService} from '../../services/ErrorService';

class AddSpending extends React.Component{
    constructor(props){
        super(props);

        this.state={
          spendingInfo:{
            name:'',
            sum:'',
            spendingDate:''
          },
          disabledDocument: false
        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount(){
        if (this.props.match.params.id) {
            spendingService.getExpense(this.props.match.params.id).then(response=>{
                let spendingInfo = { ...this.state.spendingInfo };
                spendingInfo = response.data;
                this.setState({ spendingInfo });
            })
            .catch(err=>console.log(err))
          } else {
           
          }
    }
     updateField = event => {
        const field = event.target.name;
        const spendingInfo = { ...this.state.spendingInfo };
        spendingInfo[field] = event.target.value;
        this.setState({
            spendingInfo
            });
      };
      onDropDocument = documents => {
        console.log(documents)
        let spendingInfo = { ...this.state.spendingInfo };
        let reader = new FileReader();
        let self = this;

        reader.onload = function(upload) {
            spendingInfo.document = documents[0];

          self.setState({ spendingInfo });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };

      removeDocument = () => {
        let spendingInfo = { ...this.state.spendingInfo };
        spendingInfo.document = null;
        spendingInfo.document_filename = '';

        this.setState({ spendingInfo });
        this.setState({ disabledDocument: false });
      }
      handleSubmit = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;
        spendingService
          .createOrUpdate(this.state.spendingInfo)
          .then(response => {
         
            notification.addNotification({
              message: `Documentul a fost ${
                this.state.spendingInfo.id ? 'editat' : 'adaugat'
              } cu success.`,
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            notification.addNotification({
              message:
                'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
              level: 'error',
              position: 'tc'
            });
          });
      };
    render(){
        return(
            <div className='standard agent spending'>
                <Menu/>
                <MobileMenu/>
                <div className='agent__page-content'>
                    <div className='agent__container'>
                        <img src='/images/logo.png' alt='logo' className='agent__logo'/>
                        <h2 className='agent__title'>Admin - Formular Cheltuiala</h2>
                        <form className='agent__form' onSubmit={this.handleSubmit}>
                            <h4 className='agent__label'>Denumire:</h4>
                              <input
                                className='agent__input'
                                type="text"
                                name="name"
                                onChange={this.updateField}
                                required
                                value={this.state.spendingInfo.name}/>
                        <h4 className='agent__label'>Valoare Cheltuiala (RON)</h4>
                              <input
                                className='agent__input'
                                type="number"
                                name="sum"
                                onChange={this.updateField}
                                required
                                value={this.state.spendingInfo.sum}/>
                        <h4 className='agent__label'>Data Cheltuiala:</h4>
                              <input
                                className='agent__input'
                                type="date"
                                name="spendingDate"
                                onChange={this.updateField}
                                required
                                value={this.state.spendingInfo.spendingDate}/>
                        <h4 className='agent__label'>Document Cheltuiala:</h4>
                         <AttachementField
                              onDrop={this.onDropDocument}
                              removeDocument={this.removeDocument}
                              disabledDocuments={this.state.disabledDocument}
                              document={this.state.spendingInfo.document}
                              documentFilename={
                                  this.state.spendingInfo.document_filename
                              }
                              type="image/*"
                            />
                            <div className='spending__account-box'>
                                <button type='submit' className='agent__submit-button'><h5 className='agent__button-writing'>{this.state.spendingInfo.id? 'EDITEAZA':'ADAUGA'} CHELTUIALA</h5></button>
                                
                           
                            </div>
                        </form>
                    </div>
                    </div>
                    <NotificationSystem
                      ref={this.notificationSystem}
                      style={NotificationSystemStyle}
                    />
            </div>
        )
    }
}

export default AddSpending;
