import React from 'react';
import {Link} from 'react-router-dom';
import './MobileMenu.scss';
import '../../resources/main.scss';
import menuJson from '../Menu/Menu.json';
import adminMenuJson from '../Menu/AdminMenu.json';

class MobileMenu extends React.Component{
    constructor(props){
        super(props)
        this.state={
            menu: JSON.parse(localStorage.getItem('userProfile')).role === 'admin' ? adminMenuJson : menuJson
        }
    }
    render(){
        return(
            <div className='standard mobile-menu'>
               <ul className='mobile-menu__list'>
                {this.state.menu.map(menuItem=>(
                        <div className='mobile-menu__item-box' key={menuItem.id}>
                            <Link className='standard__link' to={menuItem.url}>
                            <img src={menuItem.imgUrl} className='mobile-menu__img' alt='mobile-menu'/>
                            </Link>
                        </div>
                ))}

                </ul>
            </div>
        )
    }
}

export default MobileMenu;
