import React from 'react';
import './AddAgent.scss';
import '../../resources/main.scss';
import {Link} from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import MobileMenu from '../../components/MobileMenu/MobileMenu';
import Menu from '../../components/Menu/Menu.jsx';
import {agentService} from '../../services/AgentService';
import {loginService} from '../../services/LoginService';
import {errorService} from '../../services/ErrorService';

class AddAgent extends React.Component{
    constructor(props){
        super(props);

        this.state={
          agentInfo:{
            name:'',
            phone:'',
            email:'',
            percentage:''
          },
          adminAccess:false
        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
        loginService.checkAdmin()
          .then(response => {
            if (this.props.match.params.id) {

            }
          })
          .catch(error => {
            if (error.response.status === 403) {
              this.props.history.push("/login");
            }
          })
      }

     updateField = event => {
        const field = event.target.name;
        const agentInfo = { ...this.state.agentInfo };
        agentInfo[field] = event.target.value;
        this.setState({
            agentInfo
            });
      };
      
   handleSubmit=event=>{
    event.preventDefault();
    const notification = this.notificationSystem.current;
     agentService.addAgent(this.state.agentInfo).then(response=>{
         notification.addNotification({
             message: `Agentul a fost adaugat cu succes.`,
             level: 'success',
             position: 'tc'
           });
     })
     .catch(error=>{
         if (error.response.status === 401) {
             errorService.handleUnauthorized();
             this.props.history.push("/login");
           }

           notification.addNotification({
             message:
               'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
             level: 'error',
             position: 'tc'
           });
     })
}
    render(){
        return(
            <div className='standard agent'>
                <Menu/>
                <MobileMenu/>
                <div className='agent__page-content'>
                    <div className='agent__container'>
                        <img src='/images/logo.png' alt='logo' className='agent__logo'/>
                        <h2 className='agent__title'>Adaugă un agent imobiliar</h2>
                        <form className='agent__form'onSubmit={this.handleSubmit}>
                            <h4 className='agent__label'>Nume agent imobiliar:</h4>
                              <input
                                className='agent__input'
                                type="text"
                                name="name"
                                onChange={this.updateField}
                                required
                                value={this.state.agentInfo.name}/>
                        <h4 className='agent__label'>Număr de telefon:</h4>
                              <input
                                className='agent__input'
                                type="number"
                                name="phone"
                                onChange={this.updateField}
                                required
                                value={this.state.agentInfo.phone}/>
                        <h4 className='agent__label'>Email:</h4>
                              <input
                                className='agent__input'
                                type="email"
                                name="email"
                                onChange={this.updateField}
                                required
                                value={this.state.agentInfo.email}/>
                         <h4 className='agent__label'>Procentaj:</h4>
                              <input
                                className='agent__input'
                                type="number"
                                name="percentage"
                                onChange={this.updateField}
                                required
                                value={this.state.agentInfo.percentage}/>
                            <div className='agent__account-box'>
                                <button type='submit' className='agent__submit-button'><h5 className='agent__button-writing'>ADAUGĂ AGENT</h5></button>
                                
                           
                            </div>
                        </form>
                    </div>
                    </div>
                    <NotificationSystem
                      ref={this.notificationSystem}
                      style={NotificationSystemStyle}
                    />
            </div>
        )
    }
}

export default AddAgent;
