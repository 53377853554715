import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import '../../resources/main.scss';
import '../AddTranzaction/AddTranzaction.scss'
import Modal from 'react-modal';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';



const customStyles = {
    overlay: {
      zIndex: 12,
      backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 'auto',
      height: '85%',
    }
  };

class AddPartialSum extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
                sum: {
                    name:'',
                    date:'',
                    totalSum:'',
                    partialSum:''
              }
        };
        this.notificationSystem = React.createRef();
      }

     
      updateField = event => {
        const field = event.target.name;
        const sum = { ...this.state.sum };
        sum[field] = event.target.value;
        this.setState({
            sum
            });
      };

      


    render(){
        return(
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="tranzactions Data"
                    ariaHideApp={false}
                >
            <div className='standard add-tranzaction'>
                <img src='/images/close.png' className='add-tranzaction__close-icon' alt='close' onClick={this.props.closeModal}/>
                <h3 className='add-tranzaction__title'>Adaugă Suma Partiala</h3>
                <form className="add-tranzaction__form">
                <h3 className='add-tranzaction__label'>Adaugă numele tranzactiei:</h3>
                    <input
                        
                        className="add-tranzaction__field"
                        type="text"
                        name="name"
                        onChange={this.updateField}
                        value={this.state.sum.name}
                      />
                       <h3 className='add-tranzaction__label'>Adaugă data tranzactiei:</h3>
                    <input                   
                        className="add-tranzaction__field"
                        type="date"
                        name="registrationDate"
                        onChange={this.updateField}
                        value={this.state.sum.date}
                      />
                      <h3 className='add-tranzaction__label'>Suma totala:</h3>
                      <input                   
                        className="add-tranzaction__field"
                        type="text"
                        name="totalSum"
                        onChange={this.updateField}
                        value={this.state.sum.totalSum}
                      />
                      <h3 className='add-tranzaction__label'>Suma partiala:</h3>
                      <input                   
                        className="add-tranzaction__field"
                        type="text"
                        name="partialSum"
                        onChange={this.updateField}
                        value={this.state.sum.partialSum}
                      />
                      
                      <div className='add-tranzaction__submit-buttons'>
                      <button
                        type="submit"
                        className="add-tranzaction__submit-button"
                      >
                          <h5>ADAUGĂ SUMA</h5>
                      </button>
                      <button
                        className="add-tranzaction__cancel"
                        onClick={this.props.closeModal}
                      >
                          <h5>ANULEAZĂ</h5>
                      </button>
                      </div>
                    </form>
            </div>
            <NotificationSystem
                      ref={this.notificationSystem}
                      style={NotificationSystemStyle}
                    />
      </Modal>
            </div>
        )
    }
}

export default AddPartialSum;