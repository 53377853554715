import React from 'react';
import './Contracts.scss';
import '../../resources/main.scss';
import AttachementField from '../../components/AttachementField/AttachementField';
import {Link} from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import MobileMenu from '../../components/MobileMenu/MobileMenu';
import Menu from '../../components/Menu/Menu.jsx';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import { contractService } from '../../services/ContractService';

class Cashings extends React.Component{
    constructor(props){
        super(props);

        this.state={
            searchInfo:{
                name:'',
                month:'',
                paid:'',
                paymentMonth:'',
                agent:'',
                tranzactionType:''
            },
            showSearch:false,
            contracts: []
        }
    }

    componentDidMount(){
      contractService.getContracts().then(response=>{
          let contracts=this.state.contracts;
          contracts=response.data;
          this.setState({contracts});
      })
    }

    updateField = event => {
        const field = event.target.name;
        const searchInfo = { ...this.state.searchInfo };
        searchInfo[field] = event.target.value;
        this.setState({
            searchInfo
            });
      };

      toggleSearch=()=>{
        let showSearch=this.state.showSearch;
        showSearch=!showSearch;
        this.setState({showSearch})
      }
    render(){
        return(
            <div className='standard agent spending'>
                <Menu/>
                <MobileMenu/>
                <div className='agent__page-content'>
                    <div className='agent__container'>
                    <Link className='standard__link' to='/adauga-contract'> <button className='standard__only-desktop agents__add-button'><img src='/images/plus.png' className='agents__add-icon'/>Adauga contract</button></Link>
                        <img src='/images/logo.png' alt='logo' className='agent__logo'/>
                        <h2 className='agent__title'>Tabel tranzacții și căutări</h2>
                        <Link className='standard__link' to='/adauga-contract'> <button className='agents__add spendings__add'><img src='/images/plus.png' className='agents__add-icon'/>Adaugă contract</button></Link>
                        <div className='spendings__search-area'>
                        <form className='agent__form spendings__form'>
                            <div className='spendings__search-title' onClick={()=>this.toggleSearch()}>
                                <div>
                                <h3>Căutare</h3>
                                <img src='/images/search-green.png' alt='search' className='spendings__top-search-icon'/>
                                </div>
                                <img src='/images/arrow-down.png' alt='arrow down' className='spendings__arrow-icon'/>
                            </div>
                            {this.state.showSearch? <div className='spendings__full-search cashings__full-search'>
                            <div className='spendings__search-box-full'>
                              <h4 className='agent__label'>Denumire:</h4>
                              <input
                                className='agent__input spendings__input'
                                type="text"
                                name="name"
                                onChange={this.updateField}
                                value={this.state.searchInfo.name}/>
                            </div>
                            <div className='spendings__search-unit cashings__search-unit'>
                                <div className='spendings__search-box cashings__search-box'>
                                <h4 className='agent__label'>Luna:</h4>
                                    <select
                                    className="spendings__select"
                                    title="month"
                                    value={this.state.searchInfo.month}
                                    name="month"
                                    onChange={this.updateField}
                                    >
                                    <option value="">Luna</option>
                                    <option value="ianuarie">Ianuarie</option>
                                    <option value="februarie">Februarie</option>
                                    <option value="martie">Martie</option>
                                    <option value="aprilie">Aprilie</option>
                                    <option value="mai">Mai</option>
                                    <option value="iunie">Iunie</option>
                                    <option value="iulie">Iulie</option>
                                    <option value="august">August</option>
                                    <option value="septembrie">Septembrie</option>
                                    <option value="octombrie">Octombrie</option>
                                    <option value="noiembrie">Noiembrie</option>
                                    <option value="decembrie">Decembrie</option>

                                </select>
                                </div>
                                <div className='spendings__search-box cashings__search-box'>
                                    <h4 className='agent__label'>Agent:</h4>
                                    <select
                                        className="spendings__select"
                                        title="agent"
                                        value={this.state.searchInfo.agent}
                                        name="agent"
                                        onChange={this.updateField}
                                        >
                                        <option value="">Agent</option>
                                        <option value="Victor">Victor</option>
                                        <option value="Popescu">Popescu</option>

                                    </select>
                                </div>
                                <div className='spendings__search-box cashings__search-box'>
                                <h4 className='agent__label'>Achitat:</h4>
                                    <select
                                        className="spendings__select"
                                        title="paid"
                                        value={this.state.searchInfo.paid}
                                        name="paid"
                                        onChange={this.updateField}
                                        >
                                        <option value="">Achitat</option>
                                        <option value="ianuarie">Da</option>
                                        <option value="februarie">Nu</option>

                                    </select>
                                </div>

                                <div className='spendings__search-box cashings__search-box'>
                                <h4 className='agent__label'>Luna Achitare:</h4>
                                <select
                                    className="spendings__select"
                                    title="paymentMonth"
                                    value={this.state.searchInfo.paymentMonth}
                                    name="paymentMonth"
                                    onChange={this.updateField}
                                    >
                                    <option value="">Luna</option>
                                    <option value="ianuarie">Ianuarie</option>
                                    <option value="februarie">Februarie</option>
                                    <option value="martie">Martie</option>
                                    <option value="aprilie">Aprilie</option>
                                    <option value="mai">Mai</option>
                                    <option value="iunie">Iunie</option>
                                    <option value="iulie">Iulie</option>
                                    <option value="august">August</option>
                                    <option value="septembrie">Septembrie</option>
                                    <option value="octombrie">Octombrie</option>
                                    <option value="noiembrie">Noiembrie</option>
                                    <option value="decembrie">Decembrie</option>

                            </select>
                            </div>
                            <div className='spendings__search-box cashings__search-box'>
                                <h4 className='agent__label'>Tip tranzactie:</h4>
                                    <select
                                        className="spendings__select"
                                        title="tranzactionType"
                                        value={this.state.searchInfo.tranzactionType}
                                        name="tranzactionType"
                                        onChange={this.updateField}
                                        >
                                        <option value="">Tip tranzactie</option>
                                        <option value="vanzare">vanzare</option>
                                        <option value="inchiriere">inchiriere</option>

                                    </select>
                                </div>

                                 <button type='submit' className='agent__submit-button spendings__submit-button cashings__submit'><img src='/images/search.png' alt='search'/><h5 className='agent__button-writing'>CAUTĂ</h5></button>
                                 </div>
                                 </div> :''}
                        </form>
                        </div>

                        <div className='spendings__full-wrapper'>
                        <div className='spendings__wrapper'>
                            <h4 className='spendings__table-title'>Tabel tranzactii</h4>
                            <div className='spendings__heading'>
                                <h4 className='spendings__head-title spendings__first-cell'>Nr.</h4>
                                <h4 className='spendings__head-title spendings__cell-name'>Denumire</h4>
                                <h4 className='spendings__head-title'>Cantitate</h4>
                                <h4 className='spendings__head-title'>Data Adaugare</h4>
                                <h4 className='spendings__head-title'>Tip tranzactie</h4>
                                <h4 className='spendings__head-title'>Valoare</h4>
                                <h4 className='spendings__head-title'>Agent</h4>
                                <h4 className='spendings__head-title'>Actiuni</h4>
                            </div>
                            {this.state.contracts.map(contract=>(
                                <div className='spendings__row' key={contract.id}>
                                    <div className='spendings__head-title spendings__first-cell'>
                                      <div className='spendings__number'>
                                          <h4>{contract.id}</h4>
                                      </div>
                                    </div>

                                    <div className='spendings__cell spendings__cell-name'>
                                        <h4>{contract.name}</h4>
                                    </div>
                                    <div className='spendings__cell'>
                                        <h4>{contract.quantity}</h4>
                                    </div>
                                    <div className='spendings__cell'>
                                        <h4>{contract.contract_date ? contract.contract_date :'-'}</h4>
                                    </div>
                                    <div className='spendings__cell'>
                                        <h4>{contract.transaction_type}</h4>
                                    </div>
                                    <div className='spendings__cell'>
                                        <h4>{contract.sum}</h4>
                                    </div>
                                    <div className='spendings__cell'>
                                        <h4>{contract.agent}</h4>
                                    </div>
                                    <div className='spendings__cell'>
                                      <img src='/images/eye.png' alt='see'/>
                                    </div>
                                </div>
                            ))}

                        </div>
                        </div>
                    </div>
                    </div>

            </div>
        )
    }
}

export default Cashings;
