import React from 'react';
import './AllAgents.scss';
import '../../resources/main.scss';
import {Link} from 'react-router-dom';
import MobileMenu from '../../components/MobileMenu/MobileMenu';
import Menu from '../../components/Menu/Menu.jsx';
import agentsJson from './agents.json';
import {agentService} from '../../services/AgentService';
import {loginService} from '../../services/LoginService';

class AllAgents extends React.Component{
    constructor(props){
        super(props);

        this.state={
          agents:[]
        }
    }
    componentDidMount(){
        agentService.getAgents().then(response=>{
            let agents=this.state.agents;
            agents=response.data.users;
            this.setState({agents})
        })
    }
    render(){
        return(
            <div className='standard agent agents'>
                <Menu/>
                <MobileMenu/>
                <div className='agent__page-content agents__page-content'>
                    <div className='agent__container'>
                    <Link className='standard__link' to='/adauga-agent'> <button className='standard__only-desktop agents__add-button'><img src='/images/add-user.png' className='agents__add-icon'/>Adauga agent</button></Link>
                        <img src='/images/logo.png' alt='logo' className='agent__logo'/>
                        <h2 className='agent__title'>Agenti imobiliari</h2>
                       <Link className='standard__link' to='/adauga-agent'> <button className='agents__add'><img src='/images/add-user.png' className='agents__add-icon'/>Adauga agent</button></Link>
                       <div className='spendings__full-wrapper'>
                        <div className='spendings__wrapper'>
                            <div className='spendings__heading'>
                                <h4 className='spendings__head-title '>Nume agent imobiliar</h4>
                                <h4 className='spendings__head-title'>Numar de telefon</h4>
                                <h4 className='spendings__head-title'>Email</h4>
                                <h4 className='spendings__head-title'>Procentaj</h4>
                            </div>
                            {this.state.agents.map(agent=>(
                                <div className='spendings__row agent__row' key={agent.id}>
                                    <div className='spendings__cell'>
                                        <h4>{agent.name}</h4>
                                    </div>
                                    <div className='spendings__cell'>
                                        <h4>{agent.phone}</h4>
                                    </div>

                                    <div className='spendings__cell'>
                                        <h4>{agent.email}</h4>
                                    </div>
                                    <div className='spendings__cell'>
                                        <h4>{agent.percentage}%</h4>
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                        </div>
                    </div>
                    </div>
            </div>
        )
    }
}

export default AllAgents;
