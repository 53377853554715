import axios from 'axios';
import { loginService } from './LoginService';

export const contractService={
  createOrUpdate,
  getContracts,
  searchContract
}

function createOrUpdate(contractData) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/contracts${
    contractData.id ? `/${contractData.id}` : ''
  }`;
  const requestOptions = {
    method: contractData.id ? 'PUT' : 'POST',
    url,
    headers: {
      Accept: 'application/json'
    },
    data: buildRequestData(contractData)
  };


  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] =loginService.getToken();
  }

  return axios(requestOptions);
}

function buildRequestData(contract) {
  let formData = new FormData();

  formData.append('contract[name]', contract.name);
  formData.append('contract[quantity]', contract.quantity);
  formData.append('contract[contract_date]', contract.contractDate);
  formData.append('contract[transaction_type]', contract.transactionType);
  formData.append('contract[sum]', contract.sum);

  const document = contract.document;

  if (document && document.url !== null) {
    if (document.url) {
      formData.append(`contract[document]`, document.url);
    } else {
      formData.append(`contract[document]`, document, document.name);
    }
  }

  return formData;
}

function getContracts() {
  let url = `${process.env.REACT_APP_API_URL}/api/contracts/`;
  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json'
    }
  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] =loginService.getToken();
  }
  return axios(requestOptions);
}

function searchContract(searchData, pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/contracts/search?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  Object.keys(searchData).forEach(queryKey => {
    url += `&${queryKey}=${searchData[queryKey]}`;
  });

  const requestOptionsPost = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };
    if (loginService.loggedIn()) {
      requestOptionsPost.headers['Authorization'] =loginService.getToken();
    }

    return axios(requestOptionsPost);
}
