import axios from 'axios';
import { loginService } from './LoginService';

export const contactService={
    addContact,
    getContacts,
    searchContacts
}

function addContact(contactData){

    let url = `${process.env.REACT_APP_API_URL}/api/contacts`;
    const requestOptionsPost = {
        method: 'POST',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        data:contactData
      };
      if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] =loginService.getToken();
      }


      return axios(requestOptionsPost);
}

function getContacts(){
  let url = `${process.env.REACT_APP_API_URL}/api/contacts`;

  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json'
    }
  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] =loginService.getToken();
  }

  return axios(requestOptions);
}

function searchContacts(searchData, pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/contacts/search?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  Object.keys(searchData).forEach(queryKey => {
    url += `&${queryKey}=${searchData[queryKey]}`;
  });

  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json'
    }
  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptions);
}
