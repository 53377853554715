import axios from 'axios';
import { loginService } from './LoginService';

export const agentService={
    addAgent,
    getAgents
}

function addAgent(agentData){

    let url = `${process.env.REACT_APP_API_URL}/api/users`;
    const requestOptionsPost = {
        method: 'POST',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        data:agentData
      };
      if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] =loginService.getToken();
      }


      return axios(requestOptionsPost);
}

function getAgents(){
  let url = `${process.env.REACT_APP_API_URL}/api/users`;
  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json'
    }
  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] =loginService.getToken();
  }


  return axios(requestOptions);
}
