import React from 'react';
import './Profile.scss';
import '../../resources/main.scss';
import {Link} from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import {loginService} from '../../services/LoginService';
import MobileMenu from '../../components/MobileMenu/MobileMenu';
import Menu from '../../components/Menu/Menu.jsx';

class Profile extends React.Component{
    constructor(props){
        super(props);

        this.state={
           user:{
            currentPassword:"",
            password:"",
            passwordConfirmation:''
           }
        }
        this.notificationSystem = React.createRef();
    }
    handleChange = event => {
        const field = event.target.name;
        const user = { ...this.state.user };
        user[field] = event.target.value;
        this.setState({
          user
        });
      };
    changePassword = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;
        if (this.state.user.password !== this.state.user.passwordConfirmation) {
            notification.addNotification({
              message: 'Parolele nu corespund.',
              level: 'error',
              position: 'tc'
            });
    }
    else{
        loginService.changePassword(this.state.user)
        .then(response => {
          notification.addNotification({
            message: response.data.success,
            level: 'success',
            position: 'tc'
          });
        })
        .catch(error => {
          notification.addNotification({
            message: error.response.data.error,
            level: 'error',
            position: 'tc'
          });
        })
    }

      }
    render(){
        return(
            <div className='standard login profile'>
                <Menu/>
                <MobileMenu/>
                <div className='login__container'>
                    <img src='/images/logo.png' alt='logo' className='login__logo'/>
                    <h3 className='login__subtitle'>SCHIMBA PAROLA</h3>
                    <form className='login__form' onSubmit={this.changePassword}>
                        <h4 className='login__label'>Parola curentă:</h4>
                        <input
                              className='login__input'
                              type="password"
                              name="currentPassword"
                              onChange={this.handleChange}
                              required
                              value={this.state.user.currentPassword}/>
                        <h4 className='login__label'>Noua parolă:</h4>
                        <input
                              className='login__input'
                              type="password"
                              name="password"
                              onChange={this.handleChange}
                              required
                              value={this.state.user.password}/>
                               <h4 className='login__label'>Confirma noua parolă:</h4>
                        <input
                              className='login__input'
                              type="password"
                              name="passwordConfirmation"
                              onChange={this.handleChange}
                              required
                              value={this.state.user.passwordConfirmation}/>
                        <div className='login__account-box'>
                            <button type='submit' className='login__submit-button'><h5 className='login__button-writing'>Schimbă parola</h5></button>


                        </div>
                    </form>
                </div>
                <NotificationSystem
                  ref={this.notificationSystem}
                  style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default Profile;
