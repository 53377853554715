import axios from 'axios';
import { loginService } from './LoginService';

export const spendingService={
    createOrUpdate,
    getSpendings,
    searchExpense,
    getExpense,
    deleteExpense
}


function createOrUpdate(newSpending) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/expenses${
    newSpending.id ? `/${newSpending.id}` : ''
  }`;
  const requestOptions = {
    method: newSpending.id ? 'PUT' : 'POST',
    url,
    headers: {
      Accept: 'application/json'
    },
    data: buildRequestData(newSpending)
  };


  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] =loginService.getToken();
  }



  return axios(requestOptions);
}

function buildRequestData(expense) {
  let formData = new FormData();

  formData.append('expense[name]', expense.name);
  formData.append('expense[amount]', expense.sum);
  formData.append('expense[added_date]', expense.spendingDate);


  const document = expense.document;

  if (document && document.url !== null) {
    if (document.url) {
      formData.append(`expense[document]`, document.url);
    } else {
      formData.append(`expense[document]`, document, document.name);
    }
  }

  return formData;
}
function getSpendings(){
  let url = `${process.env.REACT_APP_API_URL}/api/expenses`;
  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json'
    }
  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] =loginService.getToken();
  }


  return axios(requestOptions);
}
function searchExpense(searchData, pageNumber){
  let url = `${process.env.REACT_APP_API_URL}/api/expenses/search?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  Object.keys(searchData).forEach(queryKey => {
    url += `&${queryKey}=${searchData[queryKey]}`;
  });

  const requestOptionsPost = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };
    if (loginService.loggedIn()) {
      requestOptionsPost.headers['Authorization'] =loginService.getToken();
    }

    return axios(requestOptionsPost);

}

function getExpense(id){
  let url = `${process.env.REACT_APP_API_URL}/api/expenses/${id}`;
  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json'
    }
  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] =loginService.getToken();
  }
  return axios(requestOptions);
}
function deleteExpense(expenseId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };

  if (loginService.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =loginService.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/expenses/${expenseId}`;

  return axios.delete(url, requestOptionsDelete);
}
