import React from 'react';
import './Spendings.scss';
import '../../resources/main.scss';
import AttachementField from '../../components/AttachementField/AttachementField';
import {Link} from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import MobileMenu from '../../components/MobileMenu/MobileMenu';
import Menu from '../../components/Menu/Menu.jsx';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import spendingsJson from './spendings.json';
import {spendingService} from '../../services/SpendingService';
import {loginService} from '../../services/LoginService';
import DeletePUGCertificates from '../../components/DeleteDialog/DeleteDialog';

class Spendings extends React.Component{
    constructor(props){
        super(props);

        this.state={
            searchInfo:{
                search_name:'',
                month:''
            },
            deleteDocument: {
                openedDialog: false,
                deleteId: 0
              },
            showSearch:false,
            spendings:[]
        }
        this.notificationSystem = React.createRef();
    }
    updateField = event => {
        const field = event.target.name;
        const searchInfo = { ...this.state.searchInfo };
        searchInfo[field] = event.target.value;
        this.setState({
            searchInfo
            });
      };
      toggleSearch=()=>{
        let showSearch=this.state.showSearch;
        showSearch=!showSearch;
        this.setState({showSearch})
      }
      componentDidMount(){
        spendingService.getSpendings().then(response=>{
            let spendings=this.state.spendings;
            spendings=response.data;
            this.setState({spendings})
        })
    }
    handleSubmit=event=>{
        event.preventDefault()
        spendingService.searchExpense(this.state.searchInfo).then(response=>{
            let spendings=this.state.spendings;
            spendings=response.data.expenses;
            this.setState({spendings});
        })
        .catch(err=>console.log(err))
    }
    openDeleteDialog = id => {
        let deleteDocument = { ...this.state.deleteDocument };
        deleteDocument = {
          openedDialog: true,
          deleteId: id
        };

        this.setState({ deleteDocument });
      };

      closeDeleteDialog = () => {
        let deleteDocument = { ...this.state.deleteDocument };
        deleteDocument = {
          openedDialog: false,
          deleteId: 0
        };

        this.setState({ deleteDocument });
      };
    handleDelete = () => {
        const notification = this.notificationSystem.current;
        spendingService
          .deleteExpense(this.state.deleteDocument.deleteId)
          .then(() => {
            let spendings = this.state.spendings.slice();
            spendings = spendings.filter(
              spending => spending.id !== this.state.deleteDocument.deleteId
            );
            this.setState({ spendings });

            this.closeDeleteDialog();

            notification.addNotification({
              message: 'Documentul a fost sters cu success.',
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            notification.addNotification({
              message:
                'A aparut o problema la stergere, faceti refresh la pagina sau contactati administratorul daca problema persista.',
              level: 'error',
              position: 'tc'
            });
          });
      };

    render(){
        return(
            <div className='standard agent spending'>
                <Menu/>
                <MobileMenu/>
                <div className='agent__page-content'>
                    <div className='agent__container'>
                    <Link className='standard__link' to='/adauga-cheltuieli'> <button className='standard__only-desktop agents__add-button'><img src='/images/plus.png' className='agents__add-icon'/>Adauga cheltuiala</button></Link>
                        <img src='/images/logo.png' alt='logo' className='agent__logo'/>
                        <h2 className='agent__title'>Tabel tranzacții și căutări</h2>
                        <Link className='standard__link' to='/adauga-cheltuieli'> <button className='agents__add spendings__add'><img src='/images/plus.png' className='agents__add-icon'/>Adaugă cheltuieli</button></Link>
                        <div className='spendings__search-area'>
                        <form className='agent__form spendings__form' onSubmit={this.handleSubmit}>
                            <div className='spendings__search-title' onClick={()=>this.toggleSearch()}>
                                <div>
                                <h3>Căutare</h3>
                                <img src='/images/search-green.png' alt='search' className='spendings__top-search-icon'/>
                                </div>
                                <img src='/images/arrow-down.png' alt='arrow down' className='spendings__arrow-icon'/>
                            </div>
                            {this.state.showSearch? <div className='spendings__full-search'>
                            <div className='spendings__search-box-full'>
                              <h4 className='agent__label'>Denumire:</h4>
                              <input
                                className='agent__input spendings__input'
                                type="text"
                                name="search_name"
                                onChange={this.updateField}
                                value={this.state.searchInfo.search_name}/>
                            </div>
                            <div className='spendings__search-box-full'>
                                <div className='spendings__search-box'>
                                <h4 className='agent__label'>Luna:</h4>
                                    <select
                                    className="spendings__select"
                                    title="month"
                                    value={this.state.searchInfo.month}
                                    name="month"
                                    onChange={this.updateField}
                                    >
                                      <option value="">Luna</option>
                                      <option value="1">Ianuarie</option>
                                      <option value="2">Februarie</option>
                                      <option value="3">Martie</option>
                                      <option value="4">Aprilie</option>
                                      <option value="5">Mai</option>
                                      <option value="6">Iunie</option>
                                      <option value="7">Iulie</option>
                                      <option value="8">August</option>
                                      <option value="9">Septembrie</option>
                                      <option value="10">Octombrie</option>
                                      <option value="11">Noiembrie</option>
                                      <option value="12">Decembrie</option>
                                    </select>
                                </div>
                            </div>
                             <button type='submit' className='agent__submit-button spendings__submit-button'><img src='/images/search.png' alt='search'/><h5 className='agent__button-writing'>CAUTĂ</h5></button>
                             </div> :''}
                        </form>
                        </div>
                        <div className='spendings__full-wrapper'>
                        <div className='spendings__wrapper'>
                            <h4 className='spendings__table-title'>Tabel tranzactii</h4>
                            <div className='spendings__heading'>
                                <h4 className='spendings__head-title spendings__first-cell'>Nr.</h4>
                                <h4 className='spendings__head-title spendings__cell-name'>Denumire</h4>
                                <h4 className='spendings__head-title'>Luna achitare</h4>
                                <h4 className='spendings__head-title'>Valoare</h4>
                                <h4 className='spendings__head-title'>Actiuni</h4>
                            </div>
                            {this.state.spendings.length ? this.state.spendings.map(spending=>(
                                <div className='spendings__row' key={spending.id}>
                                    <div className='spendings__head-title spendings__first-cell'>
                                    <div className='spendings__number'>
                                        <h4>{spending.id}</h4>
                                    </div>
                                    </div>
                                    <div className='spendings__cell spendings__cell-name'>
                                        <h4>{spending.name}</h4>
                                    </div>
                                    <div className='spendings__cell'>
                                        <h4>{spending.added_date ? spending.added_date :'-'}</h4>
                                    </div>
                                    <div className='spendings__cell'>
                                        <h4>{spending.amount}</h4>
                                    </div>
                                    <div className='spendings__cell'>
                                    {spending.document && spending.document.url !== null && (
                                        <img src='/images/eye.png' alt='see' onClick={() =>
                                            window.open(
                                            `${process.env.REACT_APP_API_URL}${
                                                spending.document.url
                                            }`
                                            )
                                        }/>

                                                )}
                                     <Link className='standard__link' to={`adauga-cheltuieli/${spending.id}`} key={spending.id}> <img src='/images/edit.png' alt='edit'/></Link>
                                      <img src='/images/delete.png' alt='see' onClick={() => this.openDeleteDialog(spending.id)}/>
                                    </div>
                                </div>
                            )) : 'Nu sunt rezultate pentru cautare.'}
                        </div>
                        </div>
                    </div>
                    </div>
                    <NotificationSystem
                      ref={this.notificationSystem}
                      style={NotificationSystemStyle}
                    />
                    <DeletePUGCertificates
                    opened={this.state.deleteDocument.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest document?"
                    aditionalText="Dupa stergere, documentul nu va mai putea fi recuperat."
                  />
            </div>
        )
    }
}

export default Spendings;
