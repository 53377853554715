import React from 'react';
import {Link} from 'react-router-dom';
import './Menu.scss';
import '../../resources/main.scss';
import menuJson from './Menu.json';
import adminMenuJson from './AdminMenu.json';

class Menu extends React.Component{
    constructor(props){
        super(props)
        this.state={
            menu: JSON.parse(localStorage.getItem('userProfile')).role === 'admin' ? adminMenuJson : menuJson
        }
    }
    render(){
        return(
            <div className='standard menu'>
                <Link to='/' className='standard__link'><h3 className='menu__title'>Invest Imobiliare</h3></Link>
               <ul className='menu__list'>
                {this.state.menu.map(menuItem=>(
                        <div className='menu__item-box' key={menuItem.id}>
                            <img src={menuItem.imgUrl} className='menu__img' alt='menu'/>
                            <Link className='standard__link' to={menuItem.url}>
                            <li className='menu__item-name'>{menuItem.item}</li>
                            </Link>
                        </div>
                ))}
                </ul>
                <Link className='standard__link' to='/profil'>
                <div className='menu__account-box'>
                    <img src='/images/account.png' className='menu__account-img' alt='account'/>
                    <h4 className='menu__item-name'>Contul Meu</h4>
                </div>
                </Link>
            </div>
        )
    }
}

export default Menu;
