import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import '../../resources/main.scss';
import './AddTranzaction.scss';
import Modal from 'react-modal';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';



const customStyles = {
    overlay: {
      zIndex: 12,
      backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 'auto',
      height: '85%',
    }
  };

class AddTranzaction extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
                contactInfo: {
                    name:'',
                    phone:'',
                    email:''
              }
        };
        this.notificationSystem = React.createRef();
      }

     
      updateField = event => {
        const field = event.target.name;
        const contactInfo = { ...this.state.contactInfo };
        contactInfo[field] = event.target.value;
        this.setState({
            contactInfo
            });
      };

      


    render(){
        return(
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="tranzactions Data"
                    ariaHideApp={false}
                >
            <div className='standard add-tranzaction'>
                <img src='/images/close.png' className='add-tranzaction__close-icon' alt='close' onClick={this.props.closeModal}/>
                <h3 className='add-tranzaction__title'>Adaugă Contact</h3>
                <h3 className='add-tranzaction__info'>Adaugă informațiile necesare pentru tranzactiA x </h3>
                <form className="add-tranzaction__form">
                <h3 className='add-tranzaction__label'>Nume:</h3>
                    <input
                        
                        className="add-tranzaction__field"
                        type="text"
                        name="name"
                        onChange={this.updateField}
                        value={this.state.contactInfo.name}
                      />
                   <h3 className='add-tranzaction__label'>Numar de telefon:</h3>
                    <input
                        
                        className="add-tranzaction__field"
                        type="number"
                        name="phone"
                        onChange={this.updateField}
                        value={this.state.contactInfo.phone}
                      />
                    <h3 className='add-tranzaction__label'>Email:</h3>
                    <input
                        
                        className="add-tranzaction__field"
                        type="email"
                        name="email"
                        onChange={this.updateField}
                        value={this.state.contactInfo.email}
                      />
                       
                      <div className='add-tranzaction__submit-buttons'>
                      <button
                        type="submit"
                        className="add-tranzaction__submit-button"
                      >
                          <h5>ADAUGĂ CONTACT</h5>
                      </button>
                      <button
                        className="add-tranzaction__cancel"
                        onClick={this.props.closeModal}
                      >
                          <h5>ANULEAZĂ</h5>
                      </button>
                      </div>
                    </form>
            </div>
            <NotificationSystem
                      ref={this.notificationSystem}
                      style={NotificationSystemStyle}
                    />
      </Modal>
            </div>
        )
    }
}

export default AddTranzaction;