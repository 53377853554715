import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import './App.css';
import Login from '../src/containers/Login/Login';
import AddAgent from '../src/containers/AddAgent/AddAgent';
import AllAgents from '../src/containers/AllAgents/AllAgents';
import AddSpending from '../src/containers/AddSpending/AddSpending';
import Spendings from '../src/containers/Spendings/Spendings.jsx';
import AddContract from '../src/containers/AddContract/AddContract';
import Contracts from '../src/containers/Contracts/Contracts';
import Contacts from '../src/containers/Contacts/Contacts';
import Profile from '../src/containers/Profile/Profile';
import {loginService} from '../src/services/LoginService';

class App extends Component{

  render(){
    return (
      <div>
        <Router>
          <div>
            <DefaultRoute exact path="/login" component={Login}/>
            <PrivateRoute exact path="/adauga-agent" component={AddAgent}/>
            <PrivateRoute exact path="/agenti-imobiliari" component={AllAgents}/>
            <PrivateRoute exact path="/adauga-cheltuieli/:id?" component={AddSpending}/>
            <PrivateRoute exact path="/cheltuieli" component={Spendings}/>
            <ProtectedRoute exact path="/adauga-contract" component={AddContract}/>
            <ProtectedRoute exact path="/contracte" component={Contracts}/>
            <ProtectedRoute exact path="/contacte" component={Contacts}/>
            <ProtectedRoute exact path="/profil" component={Profile}/>
          </div>
        </Router>
      </div>
    );
  }
}

const DefaultRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
        <Component {...matchProps} />
    )}
  />
);

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loginService.loggedIn() ? (
        <div>
          <Component {...props} />
        </div>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loginService.loggedIn() ? (
        loginService.isAdmin() ? (
          <div>
            <Component {...props} />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/contracte',
              state: { from: props.location }
            }}
          />
        )
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default App;
